
import { ref, defineComponent } from 'vue'
import * as api from '../../../utils/api'
import { STRIPE_PK } from '../../../utils/secret'
import Menu from '../../../components/menu/Hospital.vue'
import moment from 'moment-timezone'
import { useDialog } from 'naive-ui'
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js'
moment.locale('ja')
moment.tz.setDefault('Asia/Tokyo')

export default defineComponent({
	components: {
		Menu,
	},
	data() {
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		return {
			errorDialog,
			loading: true,
			process: false,
			completeInput: false,
			payMethod: 'card',
			stripe: null as Stripe | null,
			elements: null as StripeElements | null,
		}
	},
	mounted: function () {
		document.title = 'お支払い方法の追加 - Vets Index'
		this.init()
	},
	methods: {
		init: async function () {
			try {
				const data = await api.post(`/v1/hospital/subscribe/add_payment`, {})
				const { clientSecret } = data
				const stripe = await loadStripe(STRIPE_PK)
				if (!stripe) return false
				this.stripe = stripe
				const options = {
					clientSecret,
				}
				// Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 5
				const elements = stripe.elements(options)
				this.elements = elements
				// Create and mount the Payment Element
				const paymentElement = elements.create('payment')
				paymentElement.mount('#payment-element')

				const app = this
				paymentElement.on('ready', function () {
					app.loading = false
				})
				paymentElement.on('change', function (event) {
					if (event.complete) {
						app.completeInput = true
					}
				})
			} catch (e: any) {
				alert(e.message || 'エラー')
			}
		},
		pay: async function () {
			this.process = true
			const stripe = this.stripe
			const elements = this.elements
			if (!stripe || !elements) return false
			const { error } = await stripe.confirmSetup({
				elements,
				confirmParams: {
					return_url: 'http://recruit.vets-index.com/hospital/subscribe?trial=true',
				},
			})
			this.process = false
			if (error) {
				this.errorDialog(error, () => true)
			}
		},
		invoice: async function () {
			try {
				this.process = true
				const data = await api.post(`/v1/hospital/subscribe/use_invoice`, {})
				this.process = false
				this.$router.push('/hospital/subscribe')
			} catch (e: any) {
				this.process = false
				alert(e.message || 'エラー')
			}
		},
	},
})
